import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mode'
})
export class ModePipe implements PipeTransform {

  transform(value: number): string {
    let mode;
    switch (value) {
      case 0:
        mode = 'Aucune connexion';
        break;
      case 1:
        mode = '1ère tentative en GSM et 2ème tentative en Ethernet';
        break;
      case 2:
        mode = '1ère tentative en Ethernet et 2ème tentative en GSM';
        break;
      case 3:
        mode = 'GSM uniquement';
        break;
      case 4:
        mode = 'Ethernet uniquement';
        break;
      default:
        break;
    }
    return mode;
  }
}
