import {Capteur} from './capteur';
import {Adresse} from './adresse';
import {Revendeur} from './revendeur';
import {Client} from './client';
// import {chainedInstruction} from '@angular/compiler/src/render3/view/util';

type Journal = {
  boitier_id: number
  boitier_journal_status_id: number
  content: string | null
  created_at: Date | string
  id: number
  date?: Date | string
  delais?: Date | string
};

interface BoitierSaveFormat {
    noSerie: string;
    client: number
    adresse: Adresse;
    versionInstalle: number;
    versionVisible: number;
    statut: boolean;
    modeBoost: boolean;
    dateActivation: Date;
    generateura: boolean;
    generateurb: boolean;
    langue: number;
    commentaire: string;
    connectionLimitTime: number;
    diagnostic: boolean;
}

export class Boitier {
    id: number;
    noSerie: string;
    client: Client = new Client();
    adresse: Adresse = new Adresse();
    revendeur: Revendeur = new Revendeur();
    versionInstalle: number;
    versionVisible: number;
    statut: boolean;
    modeBoost: boolean;
    dateActivation: Date;
    derniereActualisation: Date;
    capteurs: Capteur[];
    generateura: boolean;
    generateurb: boolean;
    bobinea: boolean;
    bobineb: boolean;
    langue: number;
    commentaire: string;
    rapport: object;
    connectionLimitTime: number;
    journal: [Journal];
    diagnostic: boolean;

    getSaveFormat() {
        const _boitier: BoitierSaveFormat = {
            noSerie: this.noSerie,
            client: this.client ? this.client.id : null,
            adresse: this.adresse,
            versionInstalle: this.versionInstalle,
            versionVisible: this.versionVisible,
            statut: this.statut,
            modeBoost: this.modeBoost,
            dateActivation: this.dateActivation,
            generateura: this.generateura,
            generateurb: this.generateurb,
            langue: this.langue,
            commentaire: this.commentaire,
            connectionLimitTime: this.connectionLimitTime,
            diagnostic: this.diagnostic
        }
        return _boitier;
    }
}
