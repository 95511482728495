import { Injectable } from '@angular/core';
import {Capteur} from '../classes/capteur';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

interface Res {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class CapteurService {

  apiUrl = environment.apiUrl

  constructor( private http: HttpClient ) { }

  public getAllCapteurs(): Observable<Capteur[]> {
    return this.http.get<Res>(`${this.apiUrl}/capteur`)
        .pipe(
            map(res => res.data)
        );
  }

  public updateCapteur(capteur: Capteur): Observable<Capteur> {
    return this.http.put<Res>(`${this.apiUrl}/capteur/${capteur.id}`, capteur)
        .pipe(
            map(res => res.data as Capteur)
        );
  }

  public storeCapteur(capteur: Capteur): Observable<Capteur> {
    return this.http.post<Res>(`${this.apiUrl}/capteur`, capteur)
        .pipe(
            map(res => res.data as Capteur)
        )
  }

  public deleteCapteur(capteur: Capteur): Observable<any> {
    return this.http.delete(`${this.apiUrl}/capteur/${capteur.id}`)
  }
}
