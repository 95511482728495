import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'langue'
})
export class LanguePipe implements PipeTransform {

  transform(value: number): string {
    let langue;
    switch (value) {
      case 2:
        langue = 'Italien';
        break;
      case 3:
        langue = 'Anglais';
        break;
      case 4:
        langue = 'Espagnol';
        break;
      default:
        langue = 'Francais'
        break;
    }
    return langue;
  }

}
