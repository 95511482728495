import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Boitier} from '../classes/boitier';
import {map} from 'rxjs/operators';

interface Paginator {
    current_page: number;
    data: object[];
    first_page_url: string;
    from: number;
    last_page: number;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: number;
    to: number;
    total: number;
    meta: any;
}

interface Res {
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class BoitierService {

    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    public getBoitier(id): Observable<Boitier> {
        return this.http.get<Res>(`${this.baseUrl}/boitier/${id}`)
            .pipe(
                map(res => res.data as Boitier)
            );
    }

    public updateBoitier(boitier: Boitier) {
        const _boitier = new Boitier();
        Object.assign(_boitier, boitier)
        return this.http.put<Res>(`${this.baseUrl}/boitier/${boitier.id}`, _boitier.getSaveFormat())
            .pipe(
                map(res => res.data as Boitier)
            )
    }

    public updateCapteur(boitier: Boitier, capteurId, indice) {
        return this.http.post<Res>(`${this.baseUrl}/boitier/${boitier.id}/capteur`, {
            capteur_id: capteurId,
            indice
        }).pipe(
            map(res => res.data as Boitier)
        );
    }

    public getMesures(boitierId, type: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/boitier/${boitierId}/mesure?q=${type}`);
    }

    public getMesuresAll(boitierId): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/boitier/${boitierId}/mesure?q=export`);
    }

    getBoitiers(nbParPage: number = 10, page: number = 1, q: string = null): Observable<Paginator> {
        let params = new HttpParams()
            .set('nb_par_page', nbParPage.toString())
            .set('page', page.toString());
        if (q) params = params.set('q', q);

        return this.http.get<Paginator>(`${this.baseUrl}/boitier`, {params});
    }
}
