import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'version'
})
export class VersionPipe implements PipeTransform {

  transform(value: number): string {
    return 'Version 3.' + value;
  }

}
