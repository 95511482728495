import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'etat'
})
export class EtatPipe implements PipeTransform {

  transform(value: boolean): string {
    if (value) {
      return 'Actif';
    }
    return 'Inactif';
  }

}
