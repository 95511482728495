export class Capteur {
    id: number;
    nom: string;
    unite: string;
    description: string;
    couleur: string;
    type: number;
    formule: string;
    indice: number;
}
