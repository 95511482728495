import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tempsConnection'
})
export class TempsConnectionPipe implements PipeTransform {

  transform(value: number): string {
    return ''+value;
  }

}