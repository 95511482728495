import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTime'
})
export class LimitTimePipe implements PipeTransform {

  transform(value: number): string {
    let mode;
    switch (value) {
      case 0:
        mode = 'sans délais';
        break;
      case 1:
        mode = '10 Jours';
        break;
      case 2:
        mode = '30 Jours';
        break;
      case 3:
        mode = '90 Jours';
        break;
        case 4:
          mode = '180 Jours';
          break;
      default:
        break;
    }
    return mode;
  }
}
