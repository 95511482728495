import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EtatPipe } from './etat.pipe';
import { VersionPipe } from './version.pipe';
import { LanguePipe } from './langue.pipe';
import { ClientPipe } from './client.pipe';
import { AdressePipe } from './adresse.pipe';
import { TempsConnectionPipe } from './temps-connection.pipe';
import { ModePipe } from './mode.pipe';
import { LimitTimePipe } from './limit-time.pipe';

@NgModule({
  declarations: [EtatPipe, VersionPipe, LanguePipe, ClientPipe, AdressePipe, TempsConnectionPipe, ModePipe, LimitTimePipe],
  exports: [EtatPipe, VersionPipe, LanguePipe, ClientPipe, AdressePipe, TempsConnectionPipe, ModePipe, LimitTimePipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
