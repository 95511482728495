import { Pipe, PipeTransform } from '@angular/core';
import {Adresse} from '../classes/adresse';

@Pipe({
  name: 'adresse'
})
export class AdressePipe implements PipeTransform {

  transform(value: Adresse): string {
    let adresse$ = '';
    if (value === undefined) {
      return '';
    }
    adresse$ += value.streetNumber ? value.streetNumber + ' ' : '';
    adresse$ += value.route ? value.route : '';

    if (adresse$ !== '') {
      adresse$ += ', ';
    }

    adresse$ += value.postalCode ? value.postalCode + ' ' : '';
    adresse$ += value.locality ? value.locality : '';
    return adresse$;
  }

}
